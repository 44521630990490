<template>
	<Breadcrumbs></Breadcrumbs>
  <MDBCard class="m-3" :class="{ 'd-none':(add_dialog || edit_dialog )}">
    <MDBCardBody>
      <MDBContainer fluid class="mt-3">
        <MDBRow>
          <MDBCol md="2">
            <MDBInput :label="$t('COMMON.EMAIL')" :placeholder="$t('COMMON.EMAIL')" v-model="where.email" />
          </MDBCol>
          <MDBCol md="2">
            <MDBInput :label="$t('COMMON.NAME')" :placeholder="$t('COMMON.NAME')" v-model="where.name" />
          </MDBCol>
          <MDBCol md="2">
            <select class="form-select" v-model="where.status">
              <option value="">會員狀態</option>
              <option value="1">{{$t("MEMBER.STATUS_1")}}</option>
              <option value="0">{{$t("MEMBER.STATUS_0")}}</option>
            </select>
          </MDBCol>
          <MDBCol md="2">
            <select class="form-select" v-model="where.dealer">
              <option value="">經銷商狀態</option>
              <option value="1">{{$t("COMMON.YES")}}</option>
              <option value="0">{{$t("COMMON.NO")}}</option>
            </select>
          </MDBCol>
          <MDBCol class="mb-2 text-end">
            <MDBBtn color="primary" @click="GetTable">{{ $t("COMMON.SEARCH") }}</MDBBtn>
            <MDBBtn color="warning" @click="Export">{{ $t("COMMON.EXPORT") }}</MDBBtn>  
            <MDBBtn color="info" @click="add_dialog = true">{{ $t("MEMBER-LIST.ADD-ITEM") }}</MDBBtn>  
          </MDBCol>
        </MDBRow>
        <DataTable
          :header="headers"
          :tabledata="desserts"
          v-model:total="total"
          v-model:options="options"
          v-model:loading="loading"
        >
          <template v-slot:name="{ item }">
            {{ (item.first_name)?`${item.last_name}${item.first_name}`:item.name }}
          </template>
          <template v-slot:refer="{ item }">
            {{ (item.refer)?((item.refer.first_name)?`${item.refer.last_name}${item.refer.first_name}`:item.refer.name):'' }}
          </template>
          <template v-slot:refer_serial="{ item }">
            {{ (item.refer)?`${item.refer.serial}`:'' }}
          </template>
          <!-- 時間轉換 -->
          <template v-slot:created_at="{ item }">
            {{ Common.TimeFormat(item.created_at) }}
          </template>
          <template v-slot:status="{ item }">
            {{ $t("MEMBER.STATUS_" + item.status) }}
          </template>
          <template v-slot:tags="{ item }">
            <div v-if="item.tags.length == 0"></div>
            <div class="badge bg-primary text-wrap" v-for="(tag, k) in item.tags" :key="k">
              {{ tag.name }}
            </div>
          </template>
          <template v-slot:dealer="{ item }">
            {{ $t("DEALER.STATUS_" + item.dealer) }}
          </template>
          <template v-slot:total="{ item }">
            ${{ (item.statistics.filter(item => item.key == 'total_expend').length > 0)? parseFloat(item.statistics.filter(item => item.key == 'total_expend').pop().value):0  }}
          </template>
          <!-- 操作template -->
          <!-- <template v-slot:uuid="{ item }">
            <a @click="GetDetail(item.uuid)" data-bs-toggle="tooltip" :title="$t('MEMBER-LIST.EDIT-ITEM')" href="javascript:void(0)" class="me-2">
              <MDBIcon icon="edit" iconStyle="fas" class="text-primary"/>
            </a>
            <a @click="DeleteAction(item.uuid)" data-bs-toggle="tooltip" :title="$t('MEMBER-LIST.DELETE-ITEM')" href="javascript:void(0)" class="me-2">
              <MDBIcon icon="trash-alt" iconStyle="fas" class="text-danger"/>
            </a>
          </template> -->
          <template v-slot:edit="{ item }">
            <MDBBtn color="info" @click="EditUUid = item.uuid" class="px-3 py-2">
              <MDBIcon icon="edit" iconStyle="fas" class="text-white"/>
            </MDBBtn>
          </template>
          <template v-slot:uuid="{ item }">
            <MDBBtn color="danger" @click="DeleteAction(item.uuid)" class="px-3 py-2">
              <MDBIcon icon="trash-alt" iconStyle="fas" class="text-white"/>
            </MDBBtn>
          </template>
        </DataTable>
      </MDBContainer>
    </MDBCardBody>
  </MDBCard>
	<MemberForm action="insert" v-model:model="add_dialog"></MemberForm>
	<MemberForm action="update" v-model:model="edit_dialog" v-model:uuid="EditUUid"></MemberForm>
</template>

<script>
import { MDBContainer, MDBIcon, MDBBtn, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBInput } from 'mdb-vue-ui-kit';
import DataTable from '@/components/Plugin/DataTable';
import Breadcrumbs from '@/components/Breadcrumbs';
import MemberForm from '@/components/Form/Member/MemberForm';
import ApiService from "@/core/services/api.service";
import CommonService from "@/core/services/common.service";
import { ref, reactive } from 'vue';
import { useI18n } from 'vue-i18n';
import JwtService from "@/core/services/jwt.service";
export default {
  name: "MemberList",
  components: {
		DataTable,
		MDBContainer,
		MDBIcon,
		Breadcrumbs,
		MemberForm,
		MDBBtn,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBInput,
  },
  setup() {
    const i18n = useI18n();
    const add_dialog = ref(false);
    const edit_dialog = ref(false);
    const loading = ref(true);
    const EditUUid = ref("");
    const DeleteUUid = ref("");
    const desserts = ref([]);
    const total = ref(0);
    const Common = CommonService;
    const tags = reactive([]);
    const options = reactive({
      page: 1,
      itemsPerPage: 10,
      sortBy: ["created_at"],
      sortDesc: ["desc"],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false,
    });
    const where = reactive({
      email: "",
      name: "",
      sex: "",
      dealer: "",
      status: "",
    });
    const headers = reactive([
      {
        text: i18n.t("COMMON.SEQUENCE"),
        align: "start",
        sortable: false,
        value: "no",width:"50px",class: "text-center"
      },
      { text: i18n.t("COMMON.DEALERSTATUS"), value: "dealer" },
      { text: "推薦人編號", value: "refer_serial" },
      { text: i18n.t("COMMON.REFER"), value: "refer" },
      { text: "會員編號", value: "serial" },
      { text: i18n.t("COMMON.NAME"), value: "name" },
      { text: i18n.t("COMMON.EMAIL"), value: "email" },
      { text: i18n.t("COMMON.TOTALCONSUMPTION"), value: "total" },
      { text: i18n.t("COMMON.TAG"), value: "tags" },
      { text: i18n.t("COMMON.CREATE_AT"), value: "created_at" },
      { text: i18n.t("COMMON.EDIT"), value: "edit", sortable: false,width:"50px",class: "text-center"},
      { text: i18n.t("COMMON.DELETE"), value: "uuid", sortable: false,width:"50px",class: "text-center"}
    ]);
    const Export = function() {
      var obj = {
        token: JwtService.getToken(),
      };
      window.open(`${process.env.VUE_APP_API_URL}/Admin/v1/MemberExport?${Object.entries(obj).map(([k, v]) => `${k}=${encodeURIComponent(v)}`).join("&")}`);
    };
		return {
      add_dialog,
      edit_dialog,
      loading,
      EditUUid,
      DeleteUUid,
      options,
      headers,
      desserts,
      total,
      Common,
      tags,
      where,
      Export
    };
  },
  mounted() {
    this.GetTable();
    this.GetTag();
    this.$store.dispatch("country");
    this.$store.dispatch("web");
  },
  watch: {
		options: {
      handler() {
        this.GetTable();
      },
      deep: true
    }
  },
  provide () {
    return {
      GetTable: this.GetTable,
      tags: this.tags
    }
  },
  methods: {
		GetTable() {
      return new Promise(resolve => {
        this.loading = true;
        ApiService.query(
          "/Admin/v1/Member",
          {
            params: Object.assign(CommonService.SortHandleObject(this.options), this.where) 
          }
        ).then(response => {
          if (response.status == 200) {
            this.desserts = CommonService.DataNoHandle(
              response.data.data.data,
              this.options.page,
              this.options.itemsPerPage
            );
            this.total = response.data.data.total;
            resolve(response);
          }
          this.loading = false;
        });
      });
    },
    GetTag() {
      return new Promise(resolve => {
        ApiService.query(
          "/Admin/v1/Tags",
          {
            params: CommonService.SortHandleObject({
              page: 1,
              itemsPerPage: -1,
              sortBy: ["created_at"],
              sortDesc: [true]
            })
          }
        ).then(response => {
          if (response.status == 200) {
            response.data.data.data.map(record => {
              this.tags.push({
                text: record.name,
                value: record.uuid,
              });
            });
            resolve(response);
          }
        });
      });
    },
    DeleteAction(uuid) {
      CommonService.DeleteSwal().then(result => {
        if (result.isConfirmed) {
          ApiService.delete("/Admin/v1/Member/" + uuid).then(
            response => {
              if (response.status == 200) {
                CommonService.AxiosHandle(response);
                this.GetTable();
              }
            }
          );
        }
      });
    }
  }
};
</script>